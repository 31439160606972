'use client'
import {
    Carousel,
    CarouselContent,
    CarouselItem,
    CarouselNext,
    CarouselPrevious,
} from "@/components/ui/carousel"
import { ImageGenActions, useGeneratedImages } from '@/contexts/GeneratedImagesProvider'
import { getSampleImages } from '@/server/server-actions'
import { CatalogItem, ColorType } from '@/utils/APIRouteTypes'
import { cn } from "@/lib/utils"
import { Shadows_Font } from '@/utils/fonts'
import { motion } from 'framer-motion'
import { CheckCircle } from 'lucide-react'
import Image from 'next/image'
import React, { useEffect, useMemo, useState } from 'react'

export default function ImageGenerationCarousel() {

    const [imgs, setImgs] = useState<CatalogItem[]>([])
    const [generatedImages, setGeneratedImages, _] = useGeneratedImages()
    const selectedImages = useMemo(() => (new Set(imgs.filter((value) => generatedImages.some(img => img.src == value.designSrc)).map(x => x.designSrc))),
        [generatedImages.length, imgs.length])

    const loadImgs = async () => {
        const sampleImages = await getSampleImages()
        const filteredImages = sampleImages.filter(value =>
            !generatedImages.some(img => img.src === value.designSrc)
        )
        setImgs(filteredImages)
    }

    useEffect(() => {
        loadImgs()
    }, [])

    const handleImgClick = (e: React.MouseEvent<HTMLImageElement>) => {
        if (!e.currentTarget) return

        const src = e.currentTarget.dataset.src || ''

        if (!selectedImages.has(src)) {
            setGeneratedImages({ type: ImageGenActions.ADD_IMAGE, payload: [{ src, color: ColorType.LIGHT }] })
        }
        else {
            setGeneratedImages({ type: ImageGenActions.REMOVE_IMAGE, payload: { src } })
        }
    }

    if (imgs.length == 0)
        return <></>

    return (

        <motion.div
            className='px-10 pt-5 border-b pb-2 -mx-3.5'
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: 'auto', opacity: 1 }}
            transition={{
                delay: 0.1,
                duration: 0.5,
            }}
        >

            <Carousel
                opts={{
                    align: "start",
                }}
                className="w-full cursor-grab active:cursor-grabbing"
            >
                <CarouselContent hideDots className='!overflow-visible'>
                    {imgs.map((value, key) =>
                        <CarouselItem className="basis-1/3 group sm:basis-1/4 md:basis-1/5 xl:basis-1/6  flex items-center justify-center pl-1 !overflow-visible"
                            data-tooltip-id='tooltip'
                            data-tooltip-content={selectedImages.has(value.designSrc) ? "Added to your images!" : "Add to your images"}
                            key={key}
                        >
                            <div className='h-full p-0.5 border flex items-center justify-center relative rounded-md'>
                                {selectedImages.has(value.designSrc) && <div className='absolute size-full bg-black/40 text-green-300 flex items-center justify-center pointer-events-none z-50'><CheckCircle className='size-6' /></div>}
                                <Image
                                    src={value.designSrc}
                                    data-src={value.designSrc}
                                    alt='sample image'
                                    width={150}
                                    height={150}
                                    className='!max-w-full !max-h-full object-scale-down mix-blend-darken cursor-pointer group-hover:scale-105 transition-all z-30'
                                    style={{ aspectRatio: `${value.width} / ${value.height}` }}
                                    draggable={false}
                                    onClick={handleImgClick}
                                />

                                <div className={'absolute bottom-0 left-0 bg-gradient-to-t z-40 from-black/60 to-transparent w-full leading-3 p-2 pointer-events-none ' + Shadows_Font.className}>
                                    <span className={'leading-4 text-white line-clamp-2 text-xs' + Shadows_Font.className}>
                                        {value.prompt}
                                    </span>
                                </div>

                                <div className={cn("absolute size-full top-0 left-0 z-10 opacity-20 bg-gradient-to-b")} style={value.color ? {
                                    backgroundImage: value.color ? `linear-gradient(ffmain 0%,${value.color} 50%,ffaccent 100% )` : 'none',
                                    backgroundSize: "100% 150%",
                                    backgroundPosition: "center",
                                } : {}} />
                            </div>

                        </CarouselItem>
                    )
                    }
                </CarouselContent>
                <CarouselPrevious className='-translate-x-[120%]' />
                <CarouselNext className='translate-x-[120%]' />
            </Carousel>
        </motion.div>


    )
}
