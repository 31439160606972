'use client'
import React, { useEffect, useMemo, useState } from 'react'
import { FlipWords } from '../ui/flip-words'
import { useSession } from 'next-auth/react'

const maxLen = 13

const arr = [
    "Im making your design 🎨",
    "Lets chat while waiting :)",
    "Do you know that",
    "We are the first ai-store in india 🇮🇳",
    "Ye thats correct",
    "As a fellow fox, let me tell you",
    "You are one of the first",
    "To hire me... 🥹",
    "If you like my work",
    "Please share me with your friends",
    "I have 3 baby foxes to feed 🦊🦊🦊",
    "Enough chat, lets get to work",
    "I will be done soon... ✨",
]

export default function LetsTalk() {
    const session = useSession()
    const name = session.data?.user?.name

    const getInitialValue = () => {
        const localStoreIndex = localStorage.getItem('chatIndex')
        if (localStoreIndex && !isNaN(parseInt(localStoreIndex))) {
            return parseInt(localStoreIndex)
        }
        return 0
    }
    const [index, setIndex] = useState(getInitialValue)

    useEffect(() => {
        const localStoreIndex = localStorage.getItem('chatIndex')

        if (localStoreIndex && !isNaN(parseInt(localStoreIndex))) {
            if (parseInt(localStoreIndex) < index && index < maxLen - 1)
                localStorage.setItem('chatIndex', index.toString())
        }
        else {
            localStorage.setItem('chatIndex', index.toString())
        }
    }, [index])

    const lines = useMemo(() => {

        if (index !== 0) {
            const newArray = arr.slice(0, index)
            return [
                ...newArray,
                `Hi ${name ? name.split(' ')[0].toLowerCase() : ''} , im a flashy fox 🦊`,
                'You might remember me from last time',
                'I was telling you about me',
                "Im currently working on your design 🎨",
                "I will be done soon... ✨",
                "Yes, my 3 baby foxes are doing fine.",
                "Thank you for asking",
                "Hope you shared with your friends",
                "You did not? 😢",
                "You know even foxes have feelings",
                "Did you not like my work?",
                "I will try harder",
                "After all its not easy to...",
                "Run indias first ai-store 🇮🇳",
                "and im a mere fox 🦊",
                "Enough chat, lets get to work",
                "I will be done soon... ✨",
            ]
        }

        return [
            `Hi ${name ? name.split(' ')[0].toLowerCase() : ''} , im a flashy fox 🦊`,
            ...arr
        ]
    }, [name])

    return (
        <div className='h-5'>
            <FlipWords words={lines} duration={2500} index={index} setIndex={setIndex} />
        </div>
    )
}