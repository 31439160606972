"use client";
import { AnimatePresence, motion } from "framer-motion";
import React, { useCallback, useEffect, useState } from "react";

export const FlipWords = ({
  words,
  duration = 3000,
  index,
  setIndex
}: {
  words: string[];
  duration?: number;
  index: number;
  setIndex: React.Dispatch<React.SetStateAction<number>>;
}) => {

  const [isAnimating, setIsAnimating] = useState<boolean>(false);

  const startAnimation = useCallback(() => {
    if (index < words.length - 1) {
      setIndex(index + 1);
      setIsAnimating(true);
    }
  }, [index, words]);

  useEffect(() => {
    if (!isAnimating)
      setTimeout(() => {
        startAnimation();
      }, duration);
  }, [isAnimating, duration, startAnimation]);

  const lineToDisplay = index >= 0 ? words[index] : words[0];

  return (
    <AnimatePresence
      onExitComplete={() => {
        setIsAnimating(false);
      }}
    >
      <motion.p
        key={lineToDisplay}
        initial={{ opacity: 0, y: 10, filter: "blur(8px)" }}
        animate={{ opacity: 1, y: 0, filter: "blur(0px)" }}
        transition={{
          delay: 0.1,
          duration: 0.2,
        }}
        className="text-sm text-slate-500"
      >
        {lineToDisplay}
      </motion.p>
    </AnimatePresence>
  );
};


// "use client";
// import React, { useCallback, useEffect, useRef, useState } from "react";
// import { AnimatePresence, motion, LayoutGroup } from "framer-motion";
// import { cn } from "@/lib/utils";

// export const FlipWords = ({
//   words,
//   duration = 3000,
// }: {
//   words: string[];
//   duration?: number;
// }) => {
//   const [currentWord, setCurrentWord] = useState(words[0]);
//   const [isAnimating, setIsAnimating] = useState<boolean>(false);

//   const startAnimation = useCallback(() => {
//     const word = words[words.indexOf(currentWord) + 1] || words[0];
//     setCurrentWord(word);
//     setIsAnimating(true);
//   }, [currentWord, words]);

//   useEffect(() => {
//     if (!isAnimating)
//       setTimeout(() => {
//         startAnimation();
//       }, duration);
//   }, [isAnimating, duration, startAnimation]);

//   return (
//     <AnimatePresence
//       onExitComplete={() => {
//         setIsAnimating(false);
//       }}
//     >
//       <motion.span
//         key={currentWord}
//         initial={{ opacity: 0, y: 10, filter: "blur(8px)" }}
//         animate={{ opacity: 1, y: 0, filter: "blur(0px)" }}
//         transition={{
//           delay: 0.1,
//           duration: 0.2,
//         }}
//         className="h-5 text-sm text-slate-500 -m-2"
//       >
//         {currentWord}
//       </motion.span>
//     </AnimatePresence>
//   );
// };
