'use client'

import { ColorType } from '@/utils/APIRouteTypes';
import { motion } from 'framer-motion';
import { PromptImage } from './imageListItem';
type props = {
    images: { src: string, bgMode: ColorType }[];
    selectedImages: string[];
    onImageClick: (src: string) => void;
    rainbow?: boolean
}

const MINIMIZE_IMAGE_LEN = 5

export default function ImageList(props: props) {
    return (
        <motion.div className="mt-5 grid w-full grid-cols-4 gap-1 xs:grid-cols-5 md:grid-cols-6 lg:grid-cols-4 xl:grid-cols-6 overflow-visible"
            initial={{ height: '0px' }}
            animate={{ height: 'auto' }}
            transition={{
                delay: 0,
                duration: 0.3,
            }}
        >
            {props.images.map((image, index) => {
                const selected = props.selectedImages.includes(image.src)
                return (
                    <PromptImage
                        key={image.src}
                        src={image.src}
                        selected={selected}
                        onClick={props.onImageClick}
                        rainbow={props.rainbow}
                        isDark={image.bgMode === ColorType.DARK}
                    />
                )
            })}

            {/* {props.images.length > images.length + 1 && <button className='h-full aspect-square bg-black/50 rounded-md text-white text-lg sm:text-xl' onClick={handleMinimizeChange}>
                +{props.images.length - images.length}
            </button>} */}

        </motion.div>
    )
}
